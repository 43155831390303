<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.products") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card">
      <custom-table
        :fields="fields"
        :items="products"
        :loading="waiting"
        :pagination="true"
        :perPage="10"
        :searchable="true"
        :searchFields="searchFields"
        :showHeader="true"
        sortBy="stamp"
        :sortFields="sortFields"
        @row-click="(id) => router.push(`/products/${id}`)"
      >
        <template #header>
          <span class="flex justify-end"
            ><form-button
              :command="() => router.push('/products/create')"
              :icon="PlusIcon"
              id="header-product-create-button"
              label="create_product"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
          /></span>
        </template>
        <template #cell(price)="{ value }">
          {{ value / 100 }}
        </template>
        <template #cell(available)="{ value }">
          {{ value ? t("labels.yes") : t("labels.no") }}
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { PlusIcon } from "@heroicons/vue/24/solid";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import CustomTable from "../components/table/CustomTable.vue";
export default {
  components: { Breadcrumb, CustomTable, FormButton, PlusIcon },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const products = computed(() => store.state.product.products);

    const fields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "price",
        label: "price",
      },
      {
        key: "available",
        label: "available",
      },
    ]);
    const searchFields = ref(["name"]);
    const sortFields = ref(["name", "available", "price"]);

    onMounted(() => {
      store.dispatch("product/getProducts", company.value.id);
    });

    return {
      company,
      fields,
      PlusIcon,
      products,
      router,
      searchFields,
      sortFields,
      t,
      waiting: computed(() => store.state.product.waiting),
    };
  },
};
</script>
